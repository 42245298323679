<template>
<div class='content_body font_14'>
 <el-row>
     <el-col :span="20" :offset="2">
         <div class="font_weight_600">
             任务产生后 <el-input size="small" class="width_120"> </el-input> 天不处理,系统将自动作废
         </div>
     </el-col>
 </el-row>
 <el-row>
     <el-col :span="20" :offset="1">
         <div class="font_weight_600 pad_20_0">
         护理计划阶段结束后生成护理日志任务
         <el-radio v-model="generate" :label="true">是</el-radio>
         <el-radio v-model="generate" :label="false">否</el-radio>
        <span class="fw-n color_999">
            注：护理计划生成的护理日志任务指派给护理计划创建人
        </span>
         </div>
     </el-col>
 </el-row>
  <el-row>
     <el-col :span="20" :offset="1">
         <div class="font_weight_600">
         顾客消耗后给员工生成护理日志任务
         <el-radio v-model="generate" :label="true">是</el-radio>
         <el-radio v-model="generate" :label="false">否</el-radio>
        <span class="fw-n color_999">
            注：任务会自动指派给顾客消耗单中的经手人
        </span>
         </div>
     </el-col>
 </el-row>
</div>
</template>
<script>
export default {
name: 'NursingReturnVisit',
 /**  引入的组件  */
 components: {},
/**  Vue 实例的数据对象**/
data() {
return {
 generate:false,

};
},
/**计算属性  */
computed: {},
/**  方法集合  */
methods:{},
/** 监听数据变化   */
 watch: {},
/** 创建实例之前执行函数   */
beforeCreate() {},
/**  实例创建完成之后  */
created() {},
/**  在挂载开始之前被调用  */
beforeMount() {},
/**  实例被挂载后调用  */
mounted() {},
/**  数据更新 之前 调用  */
beforeUpdate() {},
/** 数据更新 完成 调用   */
updated() {},
/**  实例销毁之前调用  */
beforeDestroy() {},
/**  实例销毁后调用  */
destroyed() {},
};
</script>
 
<style lang='scss'>
.width_120{
 width: 120px;
}
.fw-n{
    font-weight: normal;
}
</style>